const sendData = (name, phone, product, OnSuccsess, OnError, onFinally) => {
  fetch(`https://api.telegram.org/bot7421535938:AAH97hWCwkRC_HmK4ohXMobMJ0vwzjXa0Ng/sendMessage?chat_id=414682869&text=${name}, 7 ${phone}, ${product}`)
    .then((response) => {
      if (response.ok) {
        OnSuccsess();
      } else throw new Error();
    })
    .catch(OnError)
    .finally(onFinally);
};

const menuHandler = (isActive, setActive) => {
  if (isActive) {
    document.body.classList.remove('no-scroll');
    setActive(false);
  } else {
    document.body.classList.add('no-scroll');
    setActive(true);
  }
};

export { sendData, menuHandler };