import React from 'react';
import { Link } from 'react-router-dom';
import styles from './ErrorPage.module.scss';

export default function ErrorPage() {
  return (
    <div className={styles.page}>
      <p>
        Запрашиваемая страница не существует. Возможно она устарела,
        была удалена, или был введен неверный адрес в адресной строке.
      </p>
      <Link className='button' to='/'>На главную</Link>
    </div>
  )
}
