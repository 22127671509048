import React, { useState } from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import VideoBlock from '../VideoBlock/VideoBlock';
import Contacts from '../Contacts/Contacts';
import { menuHandler } from '../../api';


export default function Main({ videos, isMobile }) {
   const [isFormActive, setFormActive] = useState(false);

   return (
      <>
         <HelmetProvider>
            <Helmet>
               <html lang='ru' />
               <meta charSet="utf-8" />
               <title>UgraBuilders</title>
               <meta name="description" content="строительная компания."></meta>
               <meta name="keywords" content="лучшая компания ханты-мансийск, UgraBuilders ,дом"></meta>
            </Helmet>
         </HelmetProvider>
         <VideoBlock title='Строительная компания UgraBuilders' slogan='Наша цель - исполнить вашу мечту' buttonText='Получить констультацию' video={videos[0]}
            setFormActive={() => menuHandler(isFormActive, setFormActive)} />
         <VideoBlock slogan='Счастье рядом - шагните на встречу' buttonText='Проекты' video={videos[1]} to='/programms' />
         <VideoBlock title='Мы ценим вас' slogan='Подбираем индивидуальное решение для каждого клиента' video={videos[2]} />
         <Contacts isMobile={isMobile} />
      </>
   )
}
