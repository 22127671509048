import React from 'react';
import styles from './ImageBlock.module.scss';

export default function ImageBlock({ title, secondText, picture }) {
  return (
    <div className='relative-div'>
      <img className={styles.picture} src={picture} alt=''></img>
      <section className={`${styles.section} abs-centred-section`}>
        <h1>{secondText}</h1>
        <h2>{title}</h2>
      </section>
    </div>
  )
}
