import React, { useContext } from 'react'
import { menuHandler } from '../../api';
import { Context } from '../../context';
import styles from './Product.module.scss';

export default function Product({ product }) {
  const { setProduct, setProductActive } = useContext(Context);
  return (
    <button className={styles.card}
      onClick={() => {
        setProduct(product)
        menuHandler(false, setProductActive)
      }}>
      <div className={styles.pictureWrapper}>
        <img src={product.picture} alt='' />
      </div>
      <div className={styles.info}>
        <p className={styles.type}>{product.type}</p>
        <p className={styles.title}>{product.title}</p>
        <p className={styles.benefits}>{product.benefits}</p>
      </div>
    </button>
  )
}
