import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { menuHandler } from '../../api';
import { Context } from '../../context';
import styles from './VideoBlock.module.scss';

export default function VideoBlock({ title, slogan, buttonText, video, to }) {
  const { setFormActive } = useContext(Context);
  return (
    <section className='relative-div'>
      <video className={styles.video} src={video} autoPlay loop muted playsInline></video>
      <section className={`${styles.section} abs-centred-section`}>
        <h1>{title}</h1>
        <h2>{slogan}</h2>
        {buttonText && <Link className='button' to={to}
          onClick={() => !to ? menuHandler(false, setFormActive) : false}>
          {buttonText}
        </Link>}
      </section>
    </section>
  );
}
