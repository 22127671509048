import React from 'react'
import styles from './Contacts.module.scss';

export default function Contacts({ isMobile }) {
   return (
      <address className={styles.contacts}>
         <h2>Контакты</h2>
         <a className={styles.geo} rel="noopener noreferrer" href='https://yandex.ru/maps/57/khanty-mansiysk' target="_blank">Ханты-Мансийск</a>
         <a className={styles.phone} href="tel:+79505080788">+7 9 (505) 08-07-88</a>
         <p>8:00 - 20:00</p>
      </address>
   )
}