import { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Context } from './context.js';
import Main from './components/Main/Main';
import Programms from './components/Programms/Programms';
import React, { useState } from 'react';
import { programms, other, classes } from './products.js';
import first from './assets/videos/1.mp4';
import second from './assets/videos/2.mp4';
import third from './assets/videos/3.mp4';
import Classes from './components/Classes/Classes';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Gift from './components/Gift/Gift';
import Loading from './components/Loading/Loading';
const Layout = lazy(() => import('./components/Layout/Layout'));
const videos = [first, second, third];

function App({ isMobile }) {
  const [isFormActive, setFormActive] = useState();
  const [isMenuActive, setMenuActive] = useState();
  const [isProductActive, setProductActive] = useState();
  const [product, setProduct] = useState();

  return (
    <>
      <Context.Provider
        value={{
          isMenuActive, setMenuActive,
          isFormActive, setFormActive,
          isProductActive, setProductActive, product, setProduct
        }}>
        <Routes>
          <Route path='/' element={
            <Suspense fallback={<Loading />}>
              <Layout isMobile={isMobile} />
            </Suspense>
          }>

            <Route index element={
              <Main videos={videos} isMobile={isMobile} />
            } />

            <Route path='programms' element={
              <Programms video={second}
                content={[...programms, ...other]} />
            } />


            <Route path='gift' element={<Gift />} />
            <Route path='*' element={<ErrorPage />} />
          </Route>
        </Routes>
      </Context.Provider>
    </>
  );
}

export default App;
