//Программы
import programm1 from './assets/pictures/programms/1.jpg';
import programm2 from './assets/pictures/programms/2.jpg';
import programm3 from './assets/pictures/programms/3.jpg';
import programm4 from './assets/pictures/programms/4.jpg';
import programm5 from './assets/pictures/programms/5.jpg';
import programm6 from './assets/pictures/programms/6.jpg';

//Дополнительно
import other1 from './assets/pictures/other/1.jpg';
// import other2 from './assets/pictures/other/2.jpg';
import other3 from './assets/pictures/other/3.jpg';
import other4 from './assets/pictures/other/4.jpg';
import other5 from './assets/pictures/other/5.jpg';
import other6 from './assets/pictures/other/6.jpg';
import other7 from './assets/pictures/other/7.jpg';
import other8 from './assets/pictures/other/8.jpg';
import other9 from './assets/pictures/other/9.jpg';

//Классы

import class1 from './assets/pictures/classes/class1.jpg';
import class2 from './assets/pictures/classes/class2.jpg';
import class3 from './assets/pictures/classes/class3.jpg';
import class4 from './assets/pictures/classes/class4.jpg';
import class5 from './assets/pictures/classes/class5.jpg';

const TYPES = {
    EXTERIOR: 'Проекты с плоской крышей',
    INTERIOR: 'Проекты с двухскатной крышей ',
    COMPLEX: 'Проекты с вальмововой крышей',
}

const programms = [
    {
        type: TYPES.EXTERIOR,
        title: 'A-1',
        benefits: 'Двухэтажный дом с гаражем',
        picture: programm1,
        prices: [],
        times: ['1','3 месяцев '],
        composition: [],
        description: 'Высококачественный одноэтажный дом с плоской крышей и просторным гаражом - это идеальное жилище для тех, кто предпочитает минималистичный стиль и комфортное пространство.Особенным преимуществом этого дома является его гараж, который легко рассчитан на два семейных автомобиля. Из гаража есть доступ к главному коридору, что позволяет сохранять ваш автомобиль в безопасности и удобно пользоваться им в любую погоду.'
    },
    {
      type: TYPES.EXTERIOR,
      title: 'А-2',
      benefits: 'Двухэтажный дом с балконом',
      picture: programm2,
      prices: [],
      times: ['4','6 месяцев '],
      composition: [],
      description: 'Этот дом с плоской крышей и просторным балконом – идеальный вариант для тех, кто ищет комфорт и стиль. Его современный дизайн и функциональность не оставят равнодушным никого.Дом имеет много окон, что обеспечивает естественное освещение и прекрасные виды на внешний мир.Открытый балкон на втором этаже – идеальное место для отдыха и релаксации на свежем воздухе.Этот дом с плоской крышей и балконом станет настоящим укрытием для тех, кто ищет красоту, комфорт и удобство в одном месте!'
   },
   {
      type: TYPES.COMPLEX,
      title: 'B-1',
      benefits: 'Одноэтажный дом с гаражем',
      picture: programm3,
      prices: [],
      times: ['5', '6 месяцев '],
      composition: [],
      description: 'Данный дом является прекрасным образцом современного архитектурного идеала. Его вальмовая крыша, охватывающая основное здание, придает ему необычную и практичную форму, которая впечатляет своей гармонией и стилистической изощренностью. Большие окна, размещенные по всей площади фасада, позволяют максимально использовать естественный свет и создают ощущение простора и свободы.Зона въезда в дом оформлена с помощью гаража, который расположен рядом с жилой зоной.Он способен вместить несколько автомобилей, что обеспечит своим владельцам удобство и комфорт при хранении транспорта.Кроме того, гараж может быть использован как мастерская или склад'
       
   },
    {
        type: TYPES.COMPLEX,
        title: 'B-3',
        benefits: 'Одноэтажный дом',
        picture: programm4,
        prices: [],
        times: ['4', '5 месяцев '],
        composition: [],
        description: 'В доме с вальмовой крышей нет классической грани, так как каждый угол крыши смещен, что создает впечатление симметрии и сложной формы конструкции. Такая форма крыши особенно эффективна при использовании в местах с частыми дождями или сильным снегопадом, так как позволяет надежно защитить дом от влаги, ветра и других атмосферных явлений.Дизайн дома выглядит очень инновационным, необычным и прекрасно вписывается в современную архитектуру.Данный тип крыши не только добавляет элегантности внешнему виду дома, но и позволяет максимально использовать всю площадь помещения.Дом имеет один этаж, что позволяет обеспечить максимально эффективное использование пространства.Крыша имеет некоторые гребни, которые могут быть перекрыты стильными окнами, эстетически смотрятся и позволяют почувствовать простор в доме.Общее впечатление от дома с вальмовой крышей вызывает ощущение комфорта, теплоты и безопасности.Такой дом подойдет для любого стиля интерьера и экстерьера, идеально впишется в городскую или загородную застройку.'
    },
   {
      type: TYPES.EXTERIOR,
      title: 'А-3',
      benefits: 'Одноэтажный дом',
      picture: programm5,
      prices: [],
      times: ['4', '5 месяцев '],
      composition: [],
       description: 'Современный дом с плоской крышей является идеальным примером смелой архитектурной формы и функциональности. Он представляет собой слияние простоты и минимализма в дизайне, что создает ощущение свежести и лаконичности.Такой дом имеет очень характерную форму, которая позволяет максимально использовать площадь своей территории. Плоская крыша дома является основным элементом его дизайна, привнося в него ощущение простоты, безопасности и спокойствия.'
   },
   {
      type: TYPES.EXTERIOR,
      title: 'А-4',
      benefits: 'Двухэтажный дом',
      picture: programm6,
      prices: [],
      times: ['6', '8 месяцев '],
       composition: [],
       description: 'Современный дом с плоской крышей и большим крытым балконом - это сочетание современных технологий и лаконичного дизайна, которое обеспечивает комфорт и функциональность на высшем уровне.Большой крытый балкон с крышей является одним из главных элементов дизайна такого дома. Он может быть использован как место для отдыха с семьей или друзьями. В этом месте можно насладиться прекрасными видами и всегда быть защищенным от погодных условий.'
   },
];

const other = [
   {
      type: TYPES.INTERIOR,
      title: 'С-1',
        benefits: 'Одноэтажный дом с гаражом',
      picture: other9,
      prices: [],
      times: ['4', '5 месяцев '],
      composition: [],
        description: 'Дом с двухскатной крышей и гаражом - это идеальное решение для семьи, которая ценит комфорт и безопасность своего автотранспорта. Дизайн такого дома представляет собой классическую форму крыши, дополненную своим гаражным отверстием. Это эффективное и функциональное сочетание архитектурных форм.Гараж прикреплен к дому, что дает семье возможность быстро и легко забраться в машину в любое время, не беспокоясь о неблагоприятных погодных условиях.'
   },
   {
      type: TYPES.INTERIOR,
      title: 'С-2',
      benefits: 'Одноэтажный дом с гаражом',
      picture: other1,
      prices: [],
      times: ['4', '5 месяцев '],
      composition: [],
      description: 'Дом с двухскатной крышей может иметь много красивых деталей, таких как расположенные рядом окна, выступающие элементы на фасаде и стильные декоративные элементы. Такие украшения способны придать дому особенную изюминку и уникальность.Такой дом идеально подойдет для семьи, которая ценит простоту и уютное жилье. Дом с двухскатной крышей заказывают часто потому что это надежная и просторная конструкция, которая защитит от непогоды и обеспечит комфортное проживание. Он достаточно вместительный, чтобы принимать гостей и иметь всё необходимое для комфортной жизни.'
   },
   {
      type: TYPES.EXTERIOR,
      title: 'А-5',
      benefits: 'Двухэтажный дом с гаражом и балконом',
      picture: other3,
      prices: [],
      times: ['4', '5 месяцев '],
      composition: [],
       description: 'Современный дом с плоской кровлей, гаражем и балконом - это стильное и функциональное решение для современной семьи, которая ценит комфорт и пространство. Дизайн дома придаёт ему современный вид и сочетает минималистический дизайн с практичностью и удобством.Дом имеет красивый гараж, прикрепленный к дому и предназначенный для удобства владельцев автомобилей. Гараж обеспечивает защиту и безопасность автотранспорта, что является важным фактором для многих семей.'
   },
   {
      type: TYPES.EXTERIOR,
      title: 'А-6',
      benefits: 'Двухэтажный дом с гаражом и террасой',
      picture: other4,
      prices: [],
      times: ['4', '5 месяцев '],
      composition: [],
       description: 'Современный дом с плоской кровлей, гаражем и просторной террасой - это идеальное решение для тех, кто ценит функциональность и современный стиль. Дизайн дома сочетает минималистический дизайн с удобством и комфортом, что делает его идеальным выбором для современной семьи.Дом имеет красивый гараж, который может легко вместить несколько автомобилей. Гараж обеспечивает защиту автомобилей и обеспечивает удобный доступ к основному дому.Современный дом с плоской кровлей и просторной террасой - это идеальное решение для тех, кто ищет стильный и функциональный дом. Дизайн этого дома сочетает в себе красивый минимализм с максимальным комфортом и удобством.Одной из лучших черт этого дома является его просторная терраса, которая является идеальным местом для семейных вечеров или встреч с друзьями. Эта терраса оснащена всем необходимым, чтобы создать идеальную атмосферу для отдыха и развлечений. Большие окна от пола до потолка позволяют насладиться прекрасным видом на природу вокруг дома и обеспечить естественное освещение.'
   },
   {
      type: TYPES.INTERIOR,
      title: 'C-3',
      benefits: 'Двухэтажный дом',
      picture: other5,
      prices: [],
      times: ['4', '5 месяцев '],
      composition: [],
       description: 'Современный дом с двухскатной крышей и небольшой террасой - это идеальное решение для тех, кто ищет изысканный и стильный дом с умеренными размерами. Дизайн этого дома сочетает в себе современный стиль с практичностью и удобством.Дом имеет двухскатную крышу, что является классическим решением для большинства домов. В сочетании с современным дизайном, это создаёт великолепный внешний вид строения. Небольшая терраса создаёт отличное место для отдыха и усугубляет этот внешний вид дома.'
   },
   {
      type: TYPES.EXTERIOR,
      title: 'А-7',
      benefits: 'Плоскоровельный дом с гаражем и балконом',
      picture: other8,
      prices: [],
      times: ['6', '8 месяцев '],
      composition: [],
      description: 'Дом с плоской крышей, гаражом и балконом - это современное жильё, спроектированное на смелые архитектурные решения и удобство жильцов. Он гармонирует с окружающей местностью и привлекает внимание своей неповторимой архитектурой.Плоская крыша дома великолепна и может использоваться как терраса для романтических ужинов, вечеринок под звездным небом или отдыха на свежем воздухе в любое время года. Гараж, расположенный рядом с домом, не только сохранит Ваши автомобили от неблагоприятных погодных условий, но и обеспечит дополнительное место для хранения инструментов и другого оборудования.'
   }
]

const classes = [
   {
      number: 1,
        title: 'Стандарт',
      picture: class1,
      description: ['Уточнять у менеджера']
   },
   {
      number: 2,
       title: 'Комфорт',
      picture: class2,
       description: ['Уточнять у менеджера']
   },
   {
      number: 3,
       title: 'Бизнес',
      picture: class3,
       description: ['Уточнять у менеджера']
   },
   {
      number: 4,
       title: 'Элит',
      picture: class4,
       description: ['Уточнять у менеджера']
   },
   {
      number: 5,
       title: 'Премиум',
      picture: class5,
       description: ['Уточнять у менеджера']
   }
];
export { programms, other, classes, TYPES };