import React from 'react';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { v4 } from 'uuid';
import Product from '../Product/Product';
import VideoBlock from '../VideoBlock/VideoBlock';
import styles from './Programms.module.scss';
import { TYPES } from '../../products';
import { useForm } from 'react-hook-form';


export default function Programms({ video, content }) {
   const { register, watch } = useForm({ defaultValues: { filter: '' } });
   return (
      <>
         <HelmetProvider>
            <Helmet>
               <html lang='ru' />
               <meta charSet="utf-8" />
               <title>UgraBuilders — Проекты</title>
               <meta name="keywords" content="Проекты дома, дом, мечта дом, дом мечты, умный дом, счастье"></meta>
            </Helmet>
         </HelmetProvider>
         <VideoBlock slogan='Возможные варианты проектов' video={video} />
         <div className={styles.container}>
            <form className={styles.filter}>
               <label>
                  <input type='radio' value=''
                     {...register('filter')} />
                  Все
               </label>
               {Object.values(TYPES).map(type =>
                  <label key={v4()}>
                     <input name='filter' type='radio' value={type}
                        {...register('filter')} />
                     {type}
                  </label>
               )}
            </form>
            <ul className={styles.programms}>
               {
                  content.filter(product => product.type === watch('filter')
                     || watch('filter') === '').map((product) =>
                        <li key={v4()}>
                           <Product product={product} />
                        </li>)
               }
            </ul>
         </div>
      </>
   );
}
