import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ImageBlock from '../ImageBlock/ImageBlock';
import picture from '../../assets/pictures/gift/hero.jpg';
import gifts from '../../assets/pictures/gift/gifts1.png'
import styles from './Gift.module.scss';


export default function Gift() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <html lang='ru' />
          <meta charSet="utf-8" />
          <title>UgraBuilders - о нас</title>
          <meta name="description"
            content="Полный список профильных услуг высокого качества в UgraBuilders."></meta>
          <meta name="keywords"
            content="лучшая компания ханты-мансийска, UgraBuilders, дом"></meta>
        </Helmet>
      </HelmetProvider>
      <ImageBlock
        title='Доверьте свою мечту профессионалам'
        secondText=''
        picture={picture}
      />
      <div className={styles.content}>

        <div className={styles.info}>
          <h3 className={styles.head}></h3>
          <p>
          UgraBuilders — строительная компания, расположенная в Ханты-Мансийске, которая предлагает своим клиентам высококачественные услуги в сфере строительства. Мы гордимся тем, что являемся заводом-изготовителем, специализирующимся на производстве домокомплектов по уникальной технологии, что обеспечивает надежность и долговечность наших объектов.
          <p></p>
          Наша команда состоит из опытных работников, владеющих современными методами и подходами в строительстве. Мы располагаем профессиональным оборудованием, что позволяет нам эффективно и качественно выполнять проекты любой сложности. За годы работы мы успешно построили множество объектов в городе, включая жилые и коммерческие здания, а также два полноценных района, которые стали важными элементами городской инфраструктуры.
          <p></p>
          В UgraBuilders мы понимаем, что каждый клиент уникален, и поэтому подбираем идеальные решения, соответствующие индивидуальным требованиям и пожеланиям. Наша цель — не просто построить здание, а создать пространство, которое будет отвечать всем вашим ожиданиям и потребностям.
          <p></p>
          Мы стремимся к высокому качеству и надежности, что делает нас вашим надежным партнером в сфере строительства. Выбирая UgraBuilders, вы выбираете профессионализм, опыт и индивидуальный подход к каждому клиенту!
          </p>
        </div>
      </div>
    </>
  )
}
